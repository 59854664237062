@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.screen.exception {
  height: 100vh;
  display: flex;
  width: 100%;
}
.screen.exception .ant-exception-exception {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.screen.exception .ant-exception-error {
  display: flex;
  width: 100%;
}
.screen.exception .ant-exception-error-details {
  background-color: #f5f5f5;
  overflow: scroll;
  width: 100%;
  flex: 1 1;
}
.screen.exception .ant-exception-error-details:empty {
  display: none;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
span.anchor {
  color: #3c87c2;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
span.anchor:active,
span.anchor:hover {
  outline: 0;
  text-decoration: none;
}
span.anchor:active {
  color: #28669c;
}
span.anchor:hover {
  color: #61a3cf;
}
span.anchor:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
.form-layout-actions-menu-item {
  display: flex;
  flex-direction: column;
  padding: 0.5em 1em !important;
}
.form-layout-actions-menu-item-title {
  display: block;
  color: rgba(0, 0, 0, 0.85);
}
.form-layout-actions-menu-item-description {
  display: block;
  font-size: 0.9em;
}
.form-layout-title-icon {
  margin-right: 1ch;
  color: #3c87c2;
}
.form-layout-header {
  display: flex;
  margin: 1.5em 0;
  align-items: center;
}
.form-layout-header .ant-avatar {
  margin: 0;
  width: 1.5em;
  display: flex;
  height: 1.5em;
  font-size: 3em;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
}
.form-layout-description {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.form-layout-description-item {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 1em;
  padding: 0.5em 0;
  display: flex;
}
.form-layout-description-item-name {
  color: rgba(0, 0, 0, 0.85);
  flex: 0 1 33%;
}
.form-layout-description-item-value {
  flex: 1 1;
}
.form-layout-description-item-skeleton {
  flex: 1 1;
  display: flex;
  height: 1.5em;
  margin-bottom: 1em;
  flex-direction: column;
}
.form-layout-description-item-skeleton h3 {
  margin: 0 !important;
}
.form-layout-description .ant-form-item-children {
  align-items: flex-start;
}
.form-layout-description-group-title {
  color: #3c87c2;
  margin-bottom: 2rem;
  font-weight: initial;
}
.form-layout-form-measure {
  height: 0;
}
.form-layout-form-input-clear-icon {
  opacity: 0;
  margin-left: auto;
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
.form-layout-form-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.form-layout-form-input + a.preview-tooltip {
  margin-left: 1em;
}
.form-layout-form .ant-steps {
  margin-bottom: 2rem;
}
.form-layout-form .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.form-layout-form .ant-form-item-children {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.form-layout .ant-form-item-children:hover .form-layout-form-input-clear-icon {
  opacity: 1;
}
.form-layout-footer {
  display: flex;
  margin-top: auto;
  flex-direction: row;
  justify-content: space-between;
}
.form-layout-footer-buttons > :not(:last-child) {
  margin-right: 1em;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal container styles */
.modal p {
  margin: 0;
}
.modal .ant-modal-body .ant-alert {
  margin-bottom: 1em;
}
.modal .ant-modal-body .ant-form-item:last-of-type {
  margin-bottom: 0;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */
.manage-modal-footer {
  display: flex;
  justify-content: space-between;
}
.manage-modal-footer-controls {
  margin-left: auto;
}
.manage-modal-footer-actions-menu-item {
  display: flex;
  flex-direction: column;
  padding: 0.5em 1em !important;
}
.manage-modal-footer-actions-menu-item-title {
  display: block;
  color: rgba(0, 0, 0, 0.85);
}
.manage-modal-footer-actions-menu-item-description {
  display: block;
  font-size: 0.9em;
}
.manage-modal-form-measure {
  height: 0;
}
.manage-modal-form-input {
  width: 100%;
}
.manage-modal-form .ant-upload-drag {
  height: 15em;
}
.manage-modal-form .ant-steps {
  margin-bottom: 2rem;
}
.manage-modal-form .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.manage-modal-form .ant-form-item-children {
  align-items: flex-start;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
.table-layout-filter-period-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5em 1em;
}
.table-layout-filter-slider {
  min-width: 8em;
  width: 100%;
  height: calc(2em - 4px) !important;
  margin-bottom: 1em !important;
}
.table-layout-filter-slider-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5em 2em;
}
.table-layout-filter-slider-container .ant-slider-mark {
  opacity: 0.75;
  font-size: 0.8rem;
}
.table-layout-actions-menu-item {
  display: flex;
  flex-direction: column;
  padding: 0.5em 1em !important;
}
.table-layout-actions-menu-item-title {
  display: block;
  color: rgba(0, 0, 0, 0.85);
}
.table-layout-actions-menu-item-description {
  display: block;
  font-size: 0.9em;
}
.ant-table-filter-dropdown-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-dropdown-btns > :not(:last-child) {
  margin-right: 1ch;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  padding: 5px 12px;
}
.ant-table-selection-column .ant-table-header-column > div {
  margin: auto;
}
.ant-table-search {
  padding: 1em;
  display: flex;
}
.ant-table-search input:not(:only-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-table-search button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-table-body {
  overflow: auto;
}
.ant-table-body table {
  background-color: #fff;
}
.ant-table-body table .drop-over-downward {
  border-bottom: 2px dashed #3c87c2;
}
.ant-table-body table .drop-over-upward {
  border-top: 2px dashed #3c87c2;
}
.ant-table-body table tr td {
  word-break: break-all;
}
.ant-table-body table tr thead > tr > th {
  background-clip: padding-box !important;
}
.component.table-layout {
  flex: 1 1;
}
.component.table-layout header {
  display: flex;
  align-items: center;
}
.component.table-layout header > * {
  flex: 1 1;
}
.component.table-layout header .ant-input-search {
  flex: initial;
}
.component.table-layout header .buttons {
  display: flex;
  justify-content: flex-end;
}
.component.table-layout header .ant-btn-group {
  margin-left: 1em;
}
.component.table-layout footer {
  display: flex;
  margin-top: 1em;
}
.component.table-layout footer > * {
  flex: 1 1;
}
.component.table-layout footer .ant-btn-group {
  margin-left: 1em;
}
.component.table-layout main .table-layout-table:not(:last-of-type) {
  margin-bottom: 1em;
}
.component.table-layout main,
.component.table-layout main .ant-table-wrapper,
.component.table-layout main .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
}
.component.table-layout main ul.ant-table-pagination.ant-pagination {
  position: relative;
  margin: 1em 0;
  z-index: 1;
}
.component.table-layout main ul.ant-table-pagination.ant-pagination:last-child {
  margin-bottom: 0;
}
.component.table-layout main .ant-table {
  display: inline;
}
.component.table-layout main .ant-table-header-column {
  display: flex;
}
.component.table-layout main .ant-table-column-title {
  flex: 1 1;
}
.component.table-layout main .ant-table-column-has-filters i.anticon.active {
  color: #3c87c2;
}
.component.table-layout main .ant-table-column-sorters {
  flex: 1 1;
  align-items: center;
  display: flex !important;
}
.component.table-layout main .ant-table-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 0;
  padding: 0;
  border: none;
}
.component.table-layout main .ant-table-title:empty {
  margin-bottom: 0;
}
.component.table-layout main .ant-table-title p {
  display: flex;
  margin: 0;
  margin-top: 1em;
  height: 32px;
  align-items: center;
}
.component.table-layout main .ant-table-title p > :not(:last-child) {
  margin-right: 1em;
}
.component.table-layout main .ant-table-title h2 {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}
.component.table-layout main .ant-table-empty .ant-table-body {
  border-bottom: none !important;
}
.component.table-layout main .ant-table-placeholder {
  border-bottom: none;
}
.component.table-layout main .ant-table-content {
  border: 1px solid #e8e8e8;
}
.component.table-layout main .ant-table-content-expanded-row .ant-table {
  border-radius: 0;
  display: block;
  border-top: none;
  border-right: none;
}
.component.table-layout main .ant-table-content-expanded-row .ant-table-body {
  margin: 0;
}
.component.table-layout main .ant-table-content-expanded-row .ant-table-content {
  border: none;
}
.component.table-layout main .ant-table-content .ant-table-body:not(:only-child) {
  border-bottom: 1px solid #e8e8e8;
}
.component.table-layout main .ant-table-content .ant-table-body table {
  table-layout: fixed;
  width: 100%;
}
.component.table-layout main .ant-table-content .ant-table-body table td span.anchor.delete {
  color: #f5222d;
}
.component.table-layout main .ant-table-content .ant-table-body table td span.anchor.delete span::before {
  content: ' ';
}
.component.table-layout main .ant-table-tbody > tr:last-of-type > td {
  border: none;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
.drawer-layout .ant-drawer-close {
  bottom: 0;
  height: auto;
}
.drawer-layout .ant-drawer-header {
  left: 0;
  right: 0;
  border: none;
  display: flex;
  position: absolute;
  align-items: center;
  height: 64px;
  background-color: #fff;
  padding: 0 20px;
  justify-content: space-between;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.drawer-layout .ant-drawer-body {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: 20px;
  height: calc(100vh - 64px);
}
.drawer-layout-actions-menu-item {
  display: flex;
  flex-direction: column;
  padding: 0.5em 1em !important;
}
.drawer-layout-actions-menu-item-title {
  display: block;
  color: rgba(0, 0, 0, 0.85);
}
.drawer-layout-actions-menu-item-description {
  display: block;
  font-size: 0.9em;
}
.drawer-layout-title-icon {
  margin-right: 1ch;
  color: #3c87c2;
}
.drawer-layout-header {
  display: flex;
  margin: 1.5em 0;
  align-items: center;
}
.drawer-layout-header .ant-avatar {
  margin: 0;
  width: 1.5em;
  display: flex;
  height: 1.5em;
  font-size: 3em;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
}
.drawer-layout-description {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.drawer-layout-description-item-name {
  color: rgba(0, 0, 0, 0.85);
}
.drawer-layout-description-item-name::after {
  content: ' : ';
}
.drawer-layout-description-item-skeleton {
  flex: 1 1;
  display: flex;
  height: 1.5em;
  margin-bottom: 1em;
  flex-direction: column;
}
.drawer-layout-description-item-skeleton h3 {
  margin: 0 !important;
}
.drawer-layout-description-group-title {
  color: #3c87c2;
  margin-bottom: 2rem;
  font-weight: initial;
}
.drawer-layout-footer {
  display: flex;
  margin-top: auto;
  flex-direction: row;
  justify-content: space-between;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Component styles */
.flag-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  margin: 0;
  padding: 0;
}
.flag-select img {
  width: 1.3em;
  height: 1.3em;
}
.flag-select .country-flag {
  cursor: pointer;
}
.flag-select .country-flag .country-label {
  font-size: 1rem;
  line-height: 2rem;
  position: relative;
  vertical-align: middle;
}
.flag-select .selected--flag--option {
  cursor: pointer;
  padding: 0 8px;
}
.flag-select .selected--flag--option::before {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
}
.flag-select .selected--flag--option:focus {
  outline: none;
}
.flag-select .selected--flag--option .country-label {
  margin-left: 9px;
}
.flag-select .selected--flag--option .country-flag {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flag-select .flag-options {
  position: absolute;
  z-index: 999999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: #fff;
  margin-top: 0.25rem;
  overflow: auto;
  top: 64px;
  right: 0;
}
.flag-select .flag-options.to--left {
  right: 10px;
}
.flag-select .flag-option {
  display: block;
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  cursor: pointer;
}
.flag-select .flag-option:hover {
  background-color: #f0fbff;
}
.flag-select .flag-option:focus {
  outline: none;
}
.flag-select .flag-option .country-label {
  padding: 0 9px;
}
.flag-select .filterBox {
  width: 100%;
}
.flag-select .filterBox input {
  width: 90%;
  margin: 0 4%;
}
.flag-select .filterBox input:focus {
  outline: none;
}
.flag-select .arrow-down {
  display: none;
  color: rgba(0, 0, 0, 0.65);
  padding: 2px;
}
.flag-select .hidden {
  display: none;
}
.flag-select .no--focus {
  pointer-events: none;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* StrengthIndicator styles */
.strength-indicator {
  width: 250px;
}
.strength-indicator-status-weak {
  color: #f5222d;
}
.strength-indicator-status-fair {
  color: #faad14;
}
.strength-indicator-status-good {
  color: #5db12f;
}
.strength-indicator-status-strong {
  color: #5db12f;
}
.strength-indicator-progress-weak .ant-progress-bg {
  background-color: #f5222d;
}
.strength-indicator-progress-fair .ant-progress-bg {
  background-color: #faad14;
}
.strength-indicator-progress-good .ant-progress-bg {
  background-color: #5db12f;
}
.strength-indicator-progress-strong .ant-progress-bg {
  background-color: #5db12f;
}
.strength-indicator-suggestions {
  margin: 0;
  padding: 0;
  margin-top: 1em;
}
.strength-indicator-suggestions-item {
  margin: 0;
  padding: 0;
  margin-left: 16px;
}
.strength-indicator-warning {
  margin: 0;
  padding: 0;
  margin-top: 1em;
}

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */
.modal.cancel-modal .ant-form-item-children {
  align-items: flex-start;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */
.signature-modal-footer {
  display: flex;
  justify-content: space-between;
}
.signature-modal-footer-controls {
  margin-left: auto;
}
.signature-modal-danger {
  margin-top: 20px;
}
.signature-modal-danger-text {
  color: red;
}
.modal.signature-modal .ant-form-item-control > span {
  width: 100%;
}
.modal.signature-modal .ant-form-item-children {
  align-items: flex-start;
}
.modal.signature-modal .ant-upload-btn {
  padding: 1em;
}
.modal.signature-modal .buttons {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  align-items: center;
  justify-content: center;
}
.modal.signature-modal .sigPadContainer {
  width: 100%;
}
.modal.signature-modal .sigCanvas {
  width: 100%;
  height: 200px;
  border: 1px solid;
  border-radius: 5px;
}
.modal.signature-modal .radio {
  display: block;
  height: 30px;
}

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.anchor.delete {
  color: #f5222d !important;
}
.screen.permits .permit-status .anticon {
  margin-right: 1ch;
}
.screen.permits-single .anticon-fire {
  color: #fa8c16;
}
.screen.permits-single .anticon-carry-out {
  color: #f759ab;
}
.screen.permits-single .anticon-delete {
  color: #73d13d;
}
.screen.permits-single .anticon-search {
  color: #69b1ff;
}
.screen.permits-single header {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
}
.screen.permits-single header .resume {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: row;
}
.screen.permits-single header .resume .permit-icon::after {
  content: ' ';
}
.screen.permits-single header .resume .permit-id {
  display: flex;
  align-items: center;
}
.screen.permits-single header .resume .permit-id > * {
  margin-right: 0.25em;
}
.screen.permits-single header .resume .information {
  overflow: hidden;
}
.screen.permits-single header .resume .information .ant-alert-message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.screen.permits-single header .resume .information h2.contact {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.25em;
  margin-bottom: 1rem;
}
.screen.permits-single header .resume .information h2.contact .contact {
  text-transform: uppercase;
  margin-right: 0.5ch;
}
.screen.permits-single .ant-tabs.permit-list > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div {
  display: flex;
  align-items: center;
}
.screen.permits-single .ant-tabs.permit-list > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div .ant-tabs-tab {
  padding: 0;
  /* stylelint-disable no-descending-specificity */
  /* stylelint-enable no-descending-specificity  */
}
.screen.permits-single .ant-tabs.permit-list > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div .ant-tabs-tab .permit {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 1em;
  border: none;
  border-left: 3px solid transparent;
}
.screen.permits-single .ant-tabs.permit-list > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div .ant-tabs-tab .permit-cancelled {
  border-color: #f5222d;
}
.screen.permits-single .ant-tabs.permit-list > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div .ant-tabs-tab .permit-closed {
  border-color: #722ed1;
}
.screen.permits-single .ant-tabs.permit-list > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div .ant-tabs-tab .permit-opened {
  border-color: #52c41a;
}
.screen.permits-single .ant-tabs.permit-list > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div .ant-tabs-tab .permit-finished {
  border-color: #1890ff;
}
.screen.permits-single .tab.scans .ant-form-item-children > span {
  width: 100%;
}
.screen.permits-single .tab.scans .ant-form-item-children .ant-upload .ant-btn {
  margin-top: 1em;
}
.screen.permits-single .tab.scans .ant-card-meta-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.screen.permits-single .tab.scans .ant-card-meta-title .anticon {
  margin-right: 0.25em;
}
.screen.permits-single .tab.scans .ant-card-meta-title span {
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1;
}
.screen.permits-single .tab.scans .ant-avatar {
  height: 8em;
  border-radius: 0;
  background-color: #e8e8e8;
}
.screen.permits-single .tab.changelog {
  /* stylelint-disable no-descending-specificity */
}
.screen.permits-single .tab.changelog .permit {
  /* stylelint-enable no-descending-specificity  */
}
.screen.permits-single .tab.changelog .permit-revisions-item-id::after,
.screen.permits-single .tab.changelog .permit-revisions-item-date::after {
  content: ' ';
}
.screen.permits-single .tab.changelog .permit-revisions-item-title {
  margin: 0;
}
.screen.permits-single .tab.changelog .permit-revisions-item-download {
  font-size: 1rem;
}
.screen.permits-single .tab.changelog .permit-revisions-item-download .anticon {
  margin-right: 0.5ch;
}
.screen.permits-single .tab.changelog .permit-revisions-item-changes {
  padding: 0;
  margin-top: 1em;
  list-style: none;
}
.screen.permits-single .tab.changelog .permit-revisions-item-changes-item {
  display: flex;
  align-items: center;
  padding-bottom: 0.5em;
}
.screen.permits-single .tab.changelog .permit-revisions-item-changes-item-description {
  margin: 0;
}
.screen.permits-single .tab.changelog .permit-revisions-item .ant-timeline-item-tail {
  border-left-style: dashed;
}
.screen.permits-single .permit-add-button svg {
  height: 1em;
  width: 1em;
}
.screen.permits-single .permit-add-button svg path {
  fill: currentColor !important;
}
.screen.permits-single .ant-form-item button {
  align-self: flex-start;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */
.modal.end-modal .ant-form-item-children {
  align-items: flex-start;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* == Screen styles */
.screen.profile header {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
}
.screen.profile header .resume {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: row;
}
.screen.profile header .resume .profile-lastName::before {
  content: ' ';
}
.screen.profile header .resume .profile-job::before {
  content: ' \2022 ';
}
.screen.profile header .resume .avatar-uploader {
  margin-right: 1em;
}
.screen.profile header .resume .information {
  overflow: hidden;
}
.screen.profile header .resume .information .ant-alert-message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.screen.profile header .resume .information h2.contact {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.25em;
  margin-bottom: 1rem;
}
.screen.profile header .resume .information h2.contact .contact {
  text-transform: uppercase;
  margin-right: 0.5ch;
}
.screen.profile .ant-form-item button {
  align-self: flex-start;
}

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */
.signature-modal-footer {
  display: flex;
  justify-content: space-between;
}
.signature-modal-footer-controls {
  margin-left: auto;
}
.signature-modal-danger {
  margin-top: 20px;
}
.signature-modal-danger-text {
  color: red;
}
.modal.signature-modal .ant-form-item-control > span {
  width: 100%;
}
.modal.signature-modal .ant-form-item-children {
  align-items: flex-start;
}
.modal.signature-modal .ant-upload-btn {
  padding: 1em;
}
.modal.signature-modal .buttons {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  align-items: center;
  justify-content: center;
}
.modal.signature-modal .sigPadContainer {
  width: 100%;
}
.modal.signature-modal .sigCanvas {
  width: 100%;
  height: 200px;
  border: 1px solid;
  border-radius: 5px;
}
.modal.signature-modal .radio {
  display: block;
  height: 30px;
}

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Modal styles */
.modal.chart-modal .ant-select.ant-select-enabled {
  width: 100%;
  margin-bottom: 4em;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dashboard-statistics {
  width: 100%;
}
.dashboard-statistics-title {
  padding: 20px 20px 0 20px;
  margin-bottom: 0;
}
.dashboard-statistics-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dashboard-statistics-item {
  margin: 20px;
  padding: 20px;
  background-color: white;
}
.dashboard-statistics-item.item-green {
  border-left: 2px solid #5db12f;
}
.dashboard-statistics-item.item-green .ant-statistic-content {
  color: #5db12f;
}
.dashboard-statistics-item.item-blue {
  border-left: 2px solid #108ee9;
}
.dashboard-statistics-item.item-blue .ant-statistic-content {
  color: #108ee9;
}
.dashboard-statistics-item.item-orange {
  border-left: 2px solid #ff771d;
}
.dashboard-statistics-item.item-orange .ant-statistic-content {
  color: #ff771d;
}
.dashboard-statistics-item.item-cyan {
  border-left: 2px solid #08979c;
}
.dashboard-statistics-item.item-cyan .ant-statistic-content {
  color: #08979c;
}

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:first-child {
  min-width: 5em;
}

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* == Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.screen.login {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.screen.login .ant-card {
  width: 500px;
}
.screen.login .ant-card-actions .anchor {
  display: flex;
}
.screen.login .ant-card-head {
  text-align: center;
}
.screen.login .ant-card-head-title {
  overflow: visible;
}
.screen.login .ant-card-head-title .logo {
  height: 8em;
  margin-top: -5em;
}

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Screen styles */
.screen.loading {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@font-face {
  font-family: 'icomoon';
  src: url(./static/media/icomoon.f36968cd.eot);
  src: url(./static/media/icomoon.f36968cd.eot#iefix) format('embedded-opentype'), url(./static/media/icomoon.8afd69ea.ttf) format('truetype'), url(./static/media/icomoon.806e97cf.woff) format('woff'), url(./static/media/icomoon.1b7e5b78.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-img-check .path1::before {
  content: '\e952';
  color: #386cff;
}
.icon-img-check .path2::before {
  content: '\e953';
  margin-left: -1em;
  color: #ffffff;
}
.icon-picto-armchair::before {
  content: '\e954';
  color: #dcbe61;
}
.icon-picto-blinds::before {
  content: '\e965';
}
.icon-picto-camera::before {
  content: '\e966';
}
.icon-picto-night-mode::before {
  content: '\e967';
  color: #386cff;
}
.icon-picto-out::before {
  content: '\e968';
  color: #4ecd83;
}
.icon-picto-socket::before {
  content: '\e969';
}
.icon-picto-thermostat::before {
  content: '\e96a';
}
.icon-picto-user-white::before {
  content: '\e96b';
  color: #fff;
}
.icon-favicon_huandco .path1::before {
  content: '\e96c';
  color: #ffffff;
}
.icon-favicon_huandco .path2::before {
  content: '\e96d';
  margin-left: -1em;
  color: #ffffff;
}
.icon-favicon_huandco .path3::before {
  content: '\e96e';
  margin-left: -1em;
  color: #ff643b;
}
.icon-favicon_huandco .path4::before {
  content: '\e96f';
  margin-left: -1em;
  color: #386cff;
}
.icon-picto-network-0::before {
  content: '\e900';
  color: #979797;
}
.icon-picto-network-1::before {
  content: '\e94f';
  color: #979797;
}
.icon-picto-network-2::before {
  content: '\e950';
  color: #979797;
}
.icon-picto-network-3::before {
  content: '\e951';
  color: #979797;
}
.icon-picto-dashboard::before {
  content: '\e957';
}
.icon-picto-devices::before {
  content: '\e958';
}
.icon-picto-group-setup::before {
  content: '\e959';
}
.icon-picto-keyboard::before {
  content: '\e95a';
}
.icon-picto-timeline::before {
  content: '\e95b';
}
.icon-picto-user::before {
  content: '\e95c';
}
.icon-picto-xmpp::before {
  content: '\e95d';
}
.icon-picto-dissociate::before {
  content: '\e94e';
}
.icon-picto-download::before {
  content: '\e94c';
}
.icon-picto-eye::before {
  content: '\e94d';
}
.icon-picto-edit-grey::before {
  content: '\e94b';
}
.icon-picto-calendar::before {
  content: '\e947';
  color: #fff;
}
.icon-picto-import::before {
  content: '\e948';
  color: #fff;
}
.icon-picto-print::before {
  content: '\e949';
  color: #fff;
}
.icon-picto-trash::before {
  content: '\e94a';
}
.icon-picto-home::before {
  content: '\e922';
}
.icon-logo-huandco-login .path1::before {
  content: '\e901';
  color: #ff643b;
}
.icon-logo-huandco-login .path2::before {
  content: '\e902';
  margin-left: -2.65527344em;
  color: #386cff;
}
.icon-logo-huandco-login .path3::before {
  content: '\e903';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path4::before {
  content: '\e904';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path5::before {
  content: '\e905';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path6::before {
  content: '\e906';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path7::before {
  content: '\e907';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path8::before {
  content: '\e908';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path9::before {
  content: '\e909';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path10::before {
  content: '\e90a';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path11::before {
  content: '\e90b';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path12::before {
  content: '\e90c';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path13::before {
  content: '\e90d';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path14::before {
  content: '\e90e';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path15::before {
  content: '\e90f';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path16::before {
  content: '\e910';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path17::before {
  content: '\e911';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path18::before {
  content: '\e912';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path19::before {
  content: '\e913';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path20::before {
  content: '\e914';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path21::before {
  content: '\e915';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path22::before {
  content: '\e916';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-login .path23::before {
  content: '\e95e';
  margin-left: -2.65527344em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path1::before {
  content: '\e917';
  color: #ff643b;
}
.icon-logo-huandco-menu .path2::before {
  content: '\e918';
  margin-left: -2.640625em;
  color: #386cff;
}
.icon-logo-huandco-menu .path3::before {
  content: '\e919';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path4::before {
  content: '\e91a';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path5::before {
  content: '\e91b';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path6::before {
  content: '\e91c';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path7::before {
  content: '\e91d';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path8::before {
  content: '\e91e';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path9::before {
  content: '\e91f';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path10::before {
  content: '\e920';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path11::before {
  content: '\e921';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path12::before {
  content: '\e923';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path13::before {
  content: '\e924';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path14::before {
  content: '\e925';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path15::before {
  content: '\e926';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path16::before {
  content: '\e927';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path17::before {
  content: '\e928';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path18::before {
  content: '\e929';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path19::before {
  content: '\e92a';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path20::before {
  content: '\e92b';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path21::before {
  content: '\e92c';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path22::before {
  content: '\e92d';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-logo-huandco-menu .path23::before {
  content: '\e92e';
  margin-left: -2.640625em;
  color: #ffffff;
}
.icon-picto-add::before {
  content: '\e92f';
  color: #30a4f5;
}
.icon-picto-arrow-left::before {
  content: '\e930';
  color: #30a4f5;
}
.icon-picto-arrow-right::before {
  content: '\e931';
  color: #30a4f5;
}
.icon-picto-arrow-up::before {
  content: '\e932';
}
.icon-picto-backend::before {
  content: '\e933';
}
.icon-picto-clock::before {
  content: '\e934';
  color: #fff;
}
.icon-picto-close::before {
  content: '\e935';
}
.icon-picto-cursor::before {
  content: '\e936';
}
.icon-picto-date::before {
  content: '\e937';
  color: #fff;
}
.icon-picto-design::before {
  content: '\e938';
}
.icon-picto-edit::before {
  content: '\e939';
  color: #fff;
}
.icon-picto-gateway::before {
  content: '\e93a';
}
.icon-picto-id::before {
  content: '\e93b';
  color: #fff;
}
.icon-picto-installers::before {
  content: '\e93c';
}
.icon-picto-notifications::before {
  content: '\e93d';
}
.icon-picto-password::before {
  content: '\e93e';
  color: #fff;
}
.icon-picto-radio-active::before {
  content: '\e93f';
  color: #30a4f5;
}
.icon-picto-radio-inactive::before {
  content: '\e940';
}
.icon-picto-role::before {
  content: '\e941';
}
.icon-picto-search-grey-small::before {
  content: '\e942';
}
.icon-picto-search::before {
  content: '\e943';
}
.icon-picto-settings::before {
  content: '\e944';
}
.icon-picto-transmitters::before {
  content: '\e945';
}
.icon-picto-update-menu::before {
  content: '\e946';
}

/* Fonts                          */

/* Variables */
/* Imports */
/* Fonts */
/* Inherit Ant Design config (REACT_APP_MAIN_COLOR .env) */
/* Colors */
/* Custom */
/* Metrics */
/* Fonts */
/* Mixins */
/**
*   The  pseudo-element 'content' property doesnt accept normal (&raquo;) style
*   HTML entities. These variables below easy the pain of looking up the HEX codes...
*
*   Referenced from http://www.danshort.com/HTMLentities/
*
*   TODO: Add all the other entities? Worth it? Some day? Maybe?
*/
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Mediaqueries */
/* Styles d'impression */
@media print {
  .ant-layout-sider,
  .ant-layout-header {
    display: none !important;
  }
}
/* Variables */
@media screen and (max-width: 576px) {
  .ant-exception-exception {
    text-align: center;
  }
}
/* Libraries */
/* Styles additionnels */
/* Flexbox classes (from Knacss) */
[class*='flex-container'] {
  display: flex;
}
.flex-container-h {
  flex-direction: row;
}
.flex-container-v {
  flex-direction: column;
}
.flex-item-fluid {
  flex: 1 1;
}
.flex-align-center-h {
  justify-content: center;
}
.flex-align-center-v {
  align-items: center;
}
/* Classes styles */
span.valid,
.anticon.valid {
  color: #5db12f;
}
span.important,
.anticon.important {
  color: #ff771d;
}
span.critical,
.anticon.critical {
  color: #f5222d;
}
/* Layout styles */
html,
body {
  overflow: hidden;
  font-size: 14px;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}
.hidden {
  display: none !important;
}
.period-date:not(:last-of-type)::after {
  content: ' - ';
}
main.screen,
main.tab {
  padding: 20px;
  width: 100%;
  margin-bottom: auto;
}
main.screen::after,
main.tab::after {
  content: '';
  display: block;
  height: 20px;
}
.avatar-uploader .ant-upload {
  height: 2.5em;
  width: 2.5em;
  border-radius: 100%;
}
.avatar-uploader .ant-upload .avatar {
  font-size: 2.5em;
  height: 2.5em;
  width: 2.5em;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
}
.buttons {
  text-align: right;
  margin: -0.5ch -1ch;
  margin-left: calc(1em - 1ch);
}
.buttons > * {
  margin: 0.5ch 1ch;
}
.buttons p {
  margin: 0;
  margin-top: 0.25rem;
}
/* Braft Editor Styles */
.bf-container {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.bf-container .public-DraftEditorPlaceholder-root {
  font-size: 1rem !important;
}
.bf-color-switch-buttons button {
  padding: 0 0.5em !important;
}
.bf-content {
  height: 10em !important;
  font-family: inherit !important;
  font-size: 1rem !important;
}
/* Ant Design styles */
.ant-dropdown-menu-item {
  padding: 0;
  display: flex;
}
.ant-dropdown-menu-item a,
.ant-dropdown-menu-item span.anchor {
  color: rgba(0, 0, 0, 0.65);
  padding: 5px 12px;
  margin: 0;
}
.ant-dropdown-menu-item a i.anticon,
.ant-dropdown-menu-item span.anchor i.anticon {
  margin-right: 1ch;
}
.ant-table-filter-dropdown-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-dropdown-btns > :not(:last-child) {
  margin-right: 1ch;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  padding: 5px 12px;
}
.ant-table-search {
  padding: 1em;
  display: flex;
}
.ant-table-search input:not(:only-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-table-search button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-table-body {
  overflow: auto;
}
.ant-table-body table {
  background-color: #fff;
}
.ant-table-body table thead > tr > th {
  background-clip: padding-box !important;
}
.ant-form-explain {
  margin-top: 0.25em;
}
.ant-form-item {
  margin: 0;
  position: relative;
}
.ant-form-item-children {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ant-form-item:not(:first-of-type) {
  margin-top: 1.25em;
}
.ant-form-item-control {
  line-height: 1em !important;
}
.ant-form-item-control .ant-table-content {
  border: 1px solid #e8e8e8;
}
.ant-form-item-control .ant-table-tbody > tr:last-of-type > td {
  border: none;
}
.ant-form-item-control .ant-table-title {
  display: flex;
  padding: 0;
  border: none;
  justify-content: flex-end;
  margin-top: -32px;
  margin-bottom: 1em;
}
.ant-form-item-control:not(.has-error) .ant-form-explain-holder {
  display: none;
}
.ant-form-item-control .password-rules {
  flex: 1 1;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0.5em;
  align-self: flex-start;
}
.ant-form-item-label {
  line-height: initial !important;
  margin-bottom: 0.5em !important;
}
.ant-form-item-label label::after {
  content: '';
}
.ant-form-item-label .anticon {
  margin-left: 0.5ch;
  vertical-align: middle !important;
}
.ant-select-icon {
  left: 12px;
  z-index: 1;
  display: flex;
  line-height: 1.5;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.ant-select-icon svg {
  overflow: visible;
}
.ant-select-icon + .ant-select .ant-select-selection__rendered {
  margin-left: 30px;
}
.ant-select-icon + .ant-select .ant-select-selection__placeholder {
  margin-left: 0;
}
.ant-avatar > img {
  object-fit: cover;
}
.ant-card-actions {
  display: flex;
}
.ant-card-actions > li {
  padding: 0 2em;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  float: none;
  flex: 1 1;
  width: auto !important;
}
.ant-card-actions button {
  position: relative;
  z-index: 1;
}
.ant-calendar-picker {
  width: 100%;
}
.ant-calendar-date,
.ant-calendar-date::before {
  left: 0 !important;
  right: 0 !important;
  width: auto !important;
  min-width: 24px;
}
.ant-calendar-input {
  text-align: center;
}
.ant-calendar-date-input-wrap {
  margin: 0;
}
.ant-calendar-range .ant-calendar-input {
  text-align: center !important;
}
.ant-calendar-range .ant-calendar-date-panel {
  display: flex;
}
.ant-calendar-range .ant-calendar-date-input-wrap {
  margin: 0;
  margin-left: 0;
  margin-right: 0;
}
.ant-calendar-range-middle {
  width: 100%;
  margin: 0;
  left: 0;
  right: 0;
  padding: 0;
  transform: translateX(0);
}
.ant-calendar-range-part {
  flex: 1 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.ant-progress-line,
.ant-progress-line > * {
  display: flex;
  width: 100%;
}
.ant-progress-outer {
  display: flex;
  align-items: center;
  padding-right: 0;
  margin-right: 0;
  flex: 1 1;
}
.ant-progress-text {
  display: flex;
  width: auto;
}
.ant-radio-group.vertical {
  display: flex;
  flex-direction: column;
}
.ant-tabs {
  display: inline;
}
.ant-tabs-left-bar {
  position: relative;
  z-index: 1;
}
.ant-tabs-card .ant-tabs-bar {
  margin-bottom: 0;
}
.ant-tabs-card .ant-card.tab {
  border-top: none;
}
.ant-tabs-search.ant-input-search {
  position: relative;
  display: inline-flex;
  max-width: 500px;
  float: right;
  height: 40px;
  z-index: 1;
}
.ant-tabs-search.ant-input-search input.ant-input {
  border-bottom-left-radius: 0;
  height: 40px;
  border-color: #e8e8e8;
}
.ant-tabs-search.ant-input-search .ant-input-search-button {
  border-bottom-right-radius: 0;
  height: 40px;
}
.ant-tabs.screen {
  display: flex;
  flex-direction: column;
}
.ant-tabs.screen .ant-tabs-bar {
  background-color: #fff;
  margin: 0;
}
.ant-tabs.screen .ant-tabs-content {
  margin-left: 0 !important;
  overflow-y: auto;
}
.ant-tabs.screen .ant-tabs-content .ant-tabs-tabpane-inactive {
  display: none;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.4);
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0;
  margin-top: 1.25em;
}
.ant-exception-exception {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: calc(100% - 20px);
  min-height: 500px;
}
.ant-exception-exception .ant-exception-imgBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 62.5%;
  zoom: 1;
}
.ant-exception-exception .ant-exception-imgBlock::after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.ant-exception-exception .ant-exception-imgBlock::before,
.ant-exception-exception .ant-exception-imgBlock::after {
  content: ' ';
  display: table;
}
.ant-exception-imgEle {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.ant-exception-content {
  flex: auto;
}
.ant-exception-content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.ant-exception-content .ant-exception-desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 1.25rem;
  line-height: 28px;
  margin-bottom: 1rem;
}
.ant-exception-content .ant-exception-actions button:not(:last-child) {
  margin-right: 8px;
}
.ant-menu-submenu-popup {
  background-color: rgba(0, 0, 0, 0) !important;
}
.ant-menu-submenu-selected {
  color: #fff !important;
}
.ant-menu .ant-avatar {
  margin-right: 0.5em;
  display: inline-flex;
  height: 1.25em;
  width: 1.25em;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ant-menu .ant-avatar-icon {
  background-color: #3c88c2;
}
.ant-menu .ant-avatar i {
  margin: 0;
}
/**
 * LAYOUT FULL SCREEN
 *
 * sticky header
 * scrollable content
 * sticky footer
 */
#root .ant-layout {
  height: 100vh;
}
#root .ant-layout-header {
  z-index: 1;
  display: flex;
  align-items: center;
  height: 64px;
  background-color: #fff;
  padding: 0 20px;
  justify-content: space-between;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
#root .ant-layout-header h1 {
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.5em;
}
#root .ant-layout-header h1 .breadcrumbs-crumb::after {
  content: ' / ';
}
#root .ant-layout-header nav ul {
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
}
#root .ant-layout-header nav ul li {
  height: 64px;
  display: flex;
  cursor: pointer;
}
#root .ant-layout-header nav ul li:not(:last-of-type) {
  margin-right: 1em;
}
#root .ant-layout-header .ant-dropdown-link.ant-dropdown-trigger .avatar {
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  object-fit: cover;
  margin-right: 1ch;
  height: 1.5em;
  width: 1.5em;
  font-size: 1rem;
}
#root .ant-layout-content {
  display: flex;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 64px);
}
#root .ant-layout-sider {
  z-index: 1;
  color: #fff;
  background-color: #3c87c2;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.1);
}
#root .ant-layout-sider-trigger {
  color: inherit;
  background-color: transparent;
  border-color: transparent;
}
#root .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
#root .ant-layout-sider-children > ul.ant-menu {
  overflow-y: auto;
  overflow-x: hidden;
}
#root .ant-layout-sider-children .ant-layout-sider-logo {
  background-color: #28669c;
  height: 64px;
  border-color: transparent;
}
#root .ant-layout-sider-children .ant-layout-sider-logo-image {
  padding: 1em;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#root .ant-layout-sider-children .ant-menu {
  flex: 1 1;
  color: inherit;
  background-color: transparent;
  border-color: transparent;
}
#root .ant-layout-sider-children .ant-menu-submenu-active,
#root .ant-layout-sider-children .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: #3c87c2;
}
#root .ant-layout-sider-children .ant-menu-submenu-active i::after,
#root .ant-layout-sider-children .ant-menu-submenu-title:hover i::after,
#root .ant-layout-sider-children .ant-menu-submenu-active i::before,
#root .ant-layout-sider-children .ant-menu-submenu-title:hover i::before {
  background: #fff;
}
#root .ant-layout-sider-children .ant-menu-submenu-arrow::before,
#root .ant-layout-sider-children .ant-menu-submenu-arrow::after {
  background: #fff;
}
#root .ant-layout-sider-children .ant-menu-item {
  color: inherit;
}
#root .ant-layout-sider-children .ant-menu-item-icon-image {
  height: 1em;
  width: 1em;
  min-width: 14px;
  margin-right: 10px;
  object-fit: contain;
}
#root .ant-layout-sider-children .ant-menu-item-selected {
  background-color: rgba(25, 71, 117, 0.4);
}
#root .ant-layout-sider-children .ant-menu-item:active {
  background-color: rgba(25, 71, 117, 0.3);
}
#root .ant-layout-sider-children .ant-menu-item::after {
  border-color: transparent;
}
#root .ant-layout-sider-children .ant-menu-item a {
  color: inherit;
}
#root .ant-layout-footer {
  padding: 0 20px;
  margin-top: calc(2 * 20px);
  color: rgba(0, 0, 0, 0.45);
}
#root .ant-layout-footer::after {
  content: '';
  display: block;
  height: 20px;
}
.calendar-fluid .ant-calendar {
  width: auto !important;
}
.calendar-fluid .ant-calendar-input {
  text-align: left;
}

